.component-summary {
  display: flex;
  height: 100%;
  overflow: auto;
}
.component-summary .flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.component-summary .bookmark-icon {
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.11);
}
.component-summary .close-icon {
  position: absolute;
  right: 12px;
  top: 12px;
  height: 16px;
  cursor: pointer;
}
.component-summary .left {
  flex: 0.4;
  position: relative;
}
.component-summary .left .story-card {
  position: absolute;
  bottom: 0px;
  padding-left: 16px;
  padding-bottom: 15px;
  color: #fff;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.76) 0%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  padding: 19px;
  padding-top: 40px;
}
.component-summary .left .story-card .name {
  font-size: 26px;
  font-weight: 500;
}
.component-summary .left .bg-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-image: linear-gradient(359deg, #552d83, #432870 64%, #361e5f);
  border-radius: 6px;
}
.component-summary .left .left-header {
  padding-left: 30px;
  padding-top: 15px;
  text-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
  font-family: Rubik;
  font-size: 26px;
  font-weight: 500;
  line-height: 1.23;
  color: #fff;
  position: absolute;
}
.component-summary .left .mobile-back {
  display: none;
}
.component-summary .left .mobile-bookmark {
  display: none;
}
.component-summary .description-card {
  flex: 0.6;
  width: 100%;
  border-radius: 6px;
  background-color: #f1f1f5;
}
.component-summary .description-card .summary-card {
  padding-left: 16px;
  padding-right: 20px;
}
.component-summary .description-card .summary-card .summary {
  width: 100%;
  max-height: 360px;
  overflow: auto;
  font-family: Rubik;
  font-size: 16px;
  line-height: 1.5;
  color: #000;
  margin-bottom: 15px;
}
.component-summary .description-card .summary-card .title {
  margin-bottom: 7px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.86;
  letter-spacing: -0.3px;
  color: #1e1e1e;
}
.component-summary .description-card .audioBook {
  display: flex;
  padding-left: 15px;
}
.component-summary .description-card .audioBook .text {
  font-family: Rubik;
  font-size: 14px;
  line-height: 1.71;
  color: #000;
}
.component-summary .division {
  width: 4%;
  background-color: transparent;
  height: 100%;
  flex: 0.01;
}
@media (max-width: 1023px) {
  .component-summary {
    flex-direction: column;
 }
  .component-summary .quiz-active .left {
    flex: 0.25;
 }
  .component-summary .quiz-active .description-card {
    flex: 0.75;
 }
  .component-summary .left {
    height: 30%;
 }
  .component-summary .left .mobile-back {
    color: #fff;
    top: 16px;
    position: absolute;
    left: 16px;
    font-size: 16px;
    font-weight: 500;
    display: block;
 }
  .component-summary .left .mobile-bookmark {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
 }
  .component-summary .left .bg-image {
    object-position: top;
 }
  .component-summary .description-card {
    padding-top: 12px;
 }
  .component-summary .bookmark-icon {
    display: none;
 }
}
.ant-modal-header {
  display: none;
}
.ant-modal-content {
  background-color: transparent;
}
.ant-modal-close {
  display: none;
}
.media-container {
  width: 100vw;
  height: 100vh;
  background: #fff;
}
.media-container .ant-modal-content {
  height: 100vh;
}
