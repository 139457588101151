.school-dropdown {
    border: 1px solid #d1ccc0;
    border-radius: 100px;
    position: relative;
    padding: 2px 10px;
    min-width: 180px;
    cursor: pointer;
    outline: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.css-bg1rzq-control {
    border: 1px solid #d1ccc0;
    position: relative;
    padding: 2px 10px;
    min-width: 180px;
    cursor: pointer;
    outline: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 100px!important;
}

.css-10nd86i {
    min-width: 200px !important;
}

.dropdown-container {
    min-width: 220px !important;
    margin: 0.5rem 0 !important;
}
.datepicker-wrappper input{
   width: auto;
}
