.link_school_code_form{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.link_school_code_form_btn{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

.error_message{
    color:red;
    font-size: 0.9rem;
}
