.update_grade_section_container{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.update_grade_section_title{
    font-size: 1.5rem;
    font-weight: 600;
}