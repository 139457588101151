.video-react-has-started .video-react-control-bar
{
background-color: rgba(0,0,0,0.3);}
.video-react-has-started .video-react-control-bar
{
    color:#ff8a23;
}
.video-react .video-react-play-progress {
    background-color: #ff8a23;
}
.video-react
{
    margin-right:14px;
}