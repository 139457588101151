.unlink_school_code_modal{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.unlink_btn_container{
    display: flex;
    gap: 1rem;
}