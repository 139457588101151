
.add_user_form_main{
  width: 100%;
  height: 90vh;
max-height: 70vh;
  background-color: white;
  border-radius: 10px;
  position: relative;
  z-index: 100;
  padding: 1rem;
  overflow-y: auto;
  margin: 1rem 0;
}

.add_user_form_header{
  font-size: 1.5rem;
  font-weight: 500;
}

.add_user_form_body{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-width: 50%;
  margin: 0 auto;
  /* position: absolute;
  top: 0;
  left: 0px;
  right: 0px;
  bottom: 0; */
  height: 100%;
  /* padding: 1rem; */
}

.toggle_switch_container{
  display:flex;
  /* flex-direction: column; */
  gap: 1rem;
  align-items: center;
}

.toggle_switch_container label{
  font-size: 1.4rem;
  margin-bottom: 0;
}

.toggle_switch{
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.toggle_switch p{
  margin-bottom: 0;
  font-size: 1.2rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}



.show_password{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

}

.add_user_form_body .dropdown-container{
  border: 2px solid #004ea3 !important;
  border-radius: 10px !important;
}

.file_upload_input{
  position: absolute;
  left: 0 !important;
  right: 0;
  width: 100% !important;
  height: 100%!important;
}
.upload_container{
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.file_upload_container{
  display:flex;
  flex-direction: column;
  min-height: 30vh;
  height: 100%;
  padding: 1.5rem;
  gap: 1.5rem;
  align-items: center;
  justify-content: space-evenly;
}