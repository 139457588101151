/* ...................React time picker............... */

.react-datepicker .react-datepicker--time-only {
    overflow: hidden !important;
}

ul.react-datepicker__time-list {
    padding: 0 !important;
    margin: 0;
}

.react-datepicker__day--keyboard-selected {
    background: white !important;
}

.react-datepicker__time-container {
    border-radius: 10px;
    overflow: hidden;
}

/* invite code drop down */

.css-vj8t7z {
    border-radius: 100px !important;
}

.css-10nd86i {
    min-width: 220px !important;
}

.css-z5z6cw {
    background-color: #ff8a23 !important;
}

.css-wqgs6e {
    background-color: rgba(255, 138, 35, 0.3) !important;
}

.react-datepicker__time-list-item {
    font-size: 12px;
    padding: 8px 0 !important;
    transition: 0.3s;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
    background: white;
    width: auto !important;
}

li.react-datepicker__time-list-item--selected {
    background: #ff8a23 !important;
}

/*..................html editor............*/

.rdw-embedded-modal {
    left: -75px !important;
}

.demo-editor {
    border: 1px solid #eee;
    padding: 0 0 5px 5px;
    overflow: hidden;
}

.DraftEditor-root {
    overflow: hidden;
    height: calc(100vh - 310px) !important;
}

.DraftEditor-editorContainer>div {
    overflow: auto;
}

.toolbar .rdw-embedded-modal {
    left: -75px !important;
}

.image-popup {
    left: -180px !important;
}

@media (max-width:1147px) {
    .image-popup {
        left: 0 !important;
    }
}

@media (max-width:1200px) {
    .image-popup {
        left: -200px !important;
    }
}

@media (min-width:1383px) {
    .image-popup {
        left: -20px !important;
    }
}

.Select-control {
    border-radius: 0px;
    border: 0px solid #ccc;
}

.select2-style {
    width: 100%;
}


.video-react {
    display: inline-block;
    margin-right: 35px !important;
}

.video-react .video-react-big-play-button.video-react-big-play-button-center {
    top: 50% !important;
    left: 59% !important;
    border-radius: 52px;
    margin-top: -0.75em;
    margin-left: -1.5em;
}

.video-react .video-react-big-play-button.big-play-button-hide {
    display: none;
}

.video-react .video-react-big-play-button {
    font-size: 3em;
    line-height: 1.5em;
    height: 44px !important;
    width: 44px !important;
    display: block;
    position: absolute;
    top: 10px !important;
    left: 10px !important;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    border: 0.06666em solid #fff !important;
    background-color: #2b333f !important;
    background-color: rgba(255, 255, 255, 0.18) !important;
    border-radius: 0.3em;
    transition: all 0.4s;
}

.video-react-has-started .video-react-control-bar {
    background-color: rgba(0, 0, 0, 0.3);
}

.video-react-has-started .video-react-control-bar {
    color: #ff8a23;
}

.video-react .video-react-play-progress {
    background-color: #ff8a23;
}

.video-react {
    margin-right: 14px;
}

.react-datepicker__header {
    text-align: center;
    background-color: white !important;
    border-top: none !important;
    border-bottom: none !important;
    border-top-left-radius: 0.3rem !important;
    border-top-right-radius: 0.3rem !important;
    padding-top: 8px !important;
    position: relative !important;
}

#payments-date .react-datepicker-popper[data-placement^="bottom"],
#news-freeds-date .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 10px;
    margin-left: -60px;
    z-index: 1000 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
    margin-top: 0 !important;
    color: #5a5a5a !important;
    font-weight: bold !important;
    font-size: 14px !important;
}

.react-datepicker__day {
    color: #5a5a5a !important;
}

.react-datepicker__navigation--next {
    right: 26px !important;
    border-left-color: #ccc !important;
    transform: rotate(-45deg) !important;
}

.react-datepicker__navigation--previous {
    left: 26px !important;
    transform: rotate(135deg) !important;
}

.react-datepicker-popper {
    position: absolute;
    will-change: transform;
    z-index: 1000;
    top: 0px;
    left: 0px;
    transform: translate3d(47px, 380px, 0px);
}

.react-datepicker__day--selected {
    border-radius: 100% !important;
    background-color: #ff8a23 !important;
    color: #fff !important;
}

.react-datepicker__day-name {
    color: #a5a5a5 !important;
    display: inline-block;
    width: 1.7rem;
    font-size: 12px;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__day--in-range {
    border-radius: 0.3rem;
    background-color: #ff8a23 !important;
    color: #fff;
}

.react-datepicker__day--in-selecting-range {
    background-color: #ff8a23 !important;
    opacity: 0.5;
    color: #fff !important;
}

.react-datepicker__day .react-datepicker__month-container {
    float: left;
    padding: 10px !important;
    border-radius: 14px !important;
}

.react-datepicker {
    z-index: 1000 !important;
    border-radius: 10px !important;
    /*overflow:hidden;*/
}

.react-datepicker__navigation {
    top: 24px !important;
    border: solid #d2d2d2 !important;
    border-width: 0 2px 2px 0 !important;
    padding: 2px !important;
}

.bqutSX input {
    width: 100% !important;
}

/**    REACT TOGGLE CSS           **/

.react-toggle {
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
    background-color: #ff8123;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #ff8a23;
}

.react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    background-color: #FAFAFA;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #ff8a23;
}

/* ......tab in invite code  */
.react-tabs__tab-list {
    margin: 0 !important;
    border-bottom: 2px solid transparent !important;
}


.react-tabs__tab {
    border-color: transparent;
    border: 0 !important;
    transition: all 0.3s;
}

.react-tabs__tab--selected {
    color: #432666;
    border-bottom: 2px solid #ff8a23 !important;
}

.swal2-popup .swal2-styled.swal2-confirm {
    background-color: #432666 !important;
    outline: none !important;
}

.swal2-styled {
    border-radius: 6px !important;
    font-size: 14px !important;
}

.swal2-styled:focus {
    outline: none !important;
}

.swal2-icon.swal2-question {
    border-color: #432666 !important;
    color: #432666 !important;
}

.component-media-viewer {
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10px;
}
.component-media-viewer .ant-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.component-media-viewer .audio-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.component-media-viewer .image-gallery img {
    max-width: 80vw;
    max-height: 70vh;
    object-fit: contain;
}
.component-media-viewer .close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
}
.component-media-viewer #pdf-view {
    max-height: 95vh;
    max-width: 100vw;
}
@media (max-width: 1023px) {
    .component-media-viewer {
        padding-top: 0px;
   }
    #pdf-view {
        overflow-y: auto;
        margin-top: 48px;
        min-height: 90vh;
        min-width: 90vw;
   }
    #pdf-view .pdf-outerdiv .pdf-viewer {
        border-width: 0px;
        box-shadow: none;
   }
}
.touchPDF, .touchPDF *, .touchPDF *:before, .touchPDF *:after {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
.touchPDF {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    font-family: arial, verdana, sans-serif;
    font-size: 16px;
}
.touchPDF::-webkit-scrollbar {
   /* WebKit */
    width: 0;
    height: 0;
}
.touchPDF .pdf-outerdiv {
    position: relative;
    -webkit-transform-origin: 0 0 0;
    -ms-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
}
.touchPDF .pdf-outerdiv .pdf-toolbar {
    height: 0;
    padding: 5px 0;
    text-align: center;
}
.touchPDF .pdf-outerdiv .pdf-toolbar .pdf-button {
    display: inline-block;
}
.touchPDF .pdf-outerdiv .pdf-toolbar .pdf-button .pdf-page-count {
    display: inline-block;
    min-width: 80px;
    text-align: center;
}
.touchPDF .pdf-outerdiv .pdf-viewer {
    border: 0px solid #404040;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.4);
    z-index: 2000;
    background-color: #fff;
    overflow: hidden;
    border-width: 1px;
}
.touchPDF .pdf-outerdiv .pdf-viewer .pdf-drag {
    position: absolute;
    top: 0;
    left: 0;
}
.touchPDF .pdf-outerdiv .pdf-viewer .pdf-drag canvas {
    visibility: hidden;
}
.touchPDF .pdf-outerdiv .pdf-viewer .pdf-annotations {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2500;
}
.touchPDF .pdf-outerdiv .pdf-viewer .pdf-annotations .annotLink a {
    font-size: 1em;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAA LAAAAAABAAEAAAIBRAA7") repeat scroll 0 0 rgba(0, 0, 0, 0);
}
.touchPDF .pdf-outerdiv .pdf-viewer .pdf-annotations .annotLink a:hover {
    background: none repeat scroll 0 0 #ff0;
    box-shadow: 0 2px 10px #ff0;
    opacity: 0.2;
}
.touchPDF .pdf-outerdiv .pdf-viewer .pdf-loading {
    position: absolute;
    top: 60px;
    left: 0px;
    width: 100%;
    z-index: 3000;
    text-align: center;
    vertical-align: middle;
    color: #ccc;
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}
/*FREAD-1205*/
.ans_div_mcq {
  height: 150px;
  overflow: auto;
}
.mcq_body {
  overflow: auto;
}
/*FREAD-1205*/

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
